import React, { Component } from "react"
import Img from "gatsby-image"
import styles from "./gallery-desktop.module.css"

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  next() {
    const next = this.state.currentIndex + 1
    if (next >= this.props.images.length) {
      this.setState({ currentIndex: 0 })
      return
    }
    this.setState({ currentIndex: next })
  }

  previous() {
    const previous = this.state.currentIndex - 1
    if (previous < 0) {
      this.setState({ currentIndex: this.props.images.length - 1 })
      return
    }
    this.setState({ currentIndex: previous })
  }

  getCurrentImage() {
    return this.props.images[this.state.currentIndex]
  }

  render() {
    if (this.props.images.length === 0) {
      return
    }
    const images = this.props.images
    const img = this.getCurrentImage()

    return (
      <div className={styles.container}>
        <div className={styles.slideshowWrapper}>
          <div
            className={styles.controlOverlayLeft}
            onClick={() => this.previous()}
          ></div>
          <div
            className={styles.controlOverlayRight}
            onClick={() => this.next()}
          ></div>

          {images.map((img, i) => (
            <div
              className={styles.imgContainer}
              style={{
                flexBasis: i === this.state.currentIndex ? "100%" : "0%",
                opacity: i === this.state.currentIndex ? 1 : 0,
                transition: "opacity .5s ease-in-out",
              }}
            >
              {img.image ? (
                <Img
                  fixed={img.image}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: img.image.height,
                  }}
                  imgStyle={{ objectFit: "contain" }}
                />
              ) : (
                <span>{img.title}</span>
              )}
            </div>
          ))}
        </div>
        <div className={styles.artworkDetails}>
          <p className={styles.subtitle}>{img.title} </p>

          <div
            className={styles.subtitle}
            dangerouslySetInnerHTML={{
              __html: `${img.subtitle}`,
            }}
          ></div>

          <div className={styles.controlWrapper}>
            <span className={styles.control} onClick={() => this.previous()}>
              previous
            </span>{" "}
            /{" "}
            <span className={styles.control} onClick={() => this.next()}>
              next
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Gallery
