import React from "react"
import Img from "gatsby-image"
import styles from "./gallery-mobile.module.css"

const SIZES = "calc(100vw - 40px)"

const Gallery = ({ images = [] }) => (
  <div className={styles.container}>
    {images.map((img, i) => {
      return (
        <div key={img.title} className={styles.galleryItem}>
          {img.image ? (
            <Img
              fluid={{ ...img.image, sizes: SIZES }}
              alt={i === 0 ? "" : img.title}
              objectFit="contain"
              loading={i === 0 ? "eager" : "lazy"}
            />
          ) : (
            <span>{img.title}</span>
          )}
          <div className={styles.subtitle}>
            {img.title} |
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: `${img.subtitle}`,
              }}
            ></div>
          </div>
        </div>
      )
    })}
  </div>
)

export default Gallery
