import React from "react"
import styles from "./gallery.module.css"
import GalleryMobile from "./mobile/gallery-mobile"
import GalleryDesktop from "./desktop/gallery-desktop"

const Gallery = ({ imagesMobile = [], imagesDesktop = [] }) => {
  return (
    <>
      <div className={styles.galleryMobile}>
        <GalleryMobile images={imagesMobile} />
      </div>
      <div className={styles.galleryDesktop}>
        <GalleryDesktop images={imagesDesktop} />
      </div>
    </>
  )
}

export default Gallery
